import modalTypes from "./modal.types";

const INITIAL_STATE = {
  isEasterModalW: true,
  isAug: true,
  isBlackFriday: true,
  isNewYears: true,
  isWomens: true,
  isHam: true,
  isDaytime: true,
  isHistorical: true,
  isF1promo: true,
};

const easterModalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case modalTypes.ADD_MODAL:
      return {
        ...state,
        isEasterModalW: action.payload,
      };
    case "SET_AUG":
      return {
        ...state,
        isAug: action.payload,
      };
    case "SET_BLACK":
      return {
        ...state,
        isBlackFriday: action.payload,
      };
    case "SET_NEWYEARS":
      return {
        ...state,
        isNewYears: action.payload,
      };
    case "SET_WOMENS":
      return {
        ...state,
        isWomens: action.payload,
      };
    case "SET_HAM":
      return {
        ...state,
        isHam: action.payload,
      };
    case "SET_DAYTIME":
      return {
        ...state,
        isDaytime: action.payload,
      };
    case "SET_F1PROMO":
      return {
        ...state,
        isF1promo: action.payload,
      };
    case "SET_HISTORICAL":
      return {
        ...state,
        isHistorical: action.payload,
      };
    default:
      return state;
  }
};

export default easterModalReducer;
